import React from "react"
import { Link } from "gatsby"

import "./article.scss"

export function filterArticles(a, b) {
  const dateA = new Date(a.node.frontmatter.date)
  const dateB = new Date(b.node.frontmatter.date)

  return dateA < dateB ? 1 : -1
}

function Articles({ articles, minimal }) {
  const className = minimal ? "articles--minimial" : "articles--full"
  return (
    <div id="articles-container" className={className}>
      {articles.sort(filterArticles).map(({ node }) => (
        <Link
          className="article__item"
          key={node.id}
          to={`/articles${node.fields.slug}`}
        >
          {node.frontmatter.image && (
            <div
              className="article__item__image"
              style={{ backgroundImage: `url(${node.frontmatter.image})` }}
            />
          )}
          <h4 className="article__item__title">{node.frontmatter.title}</h4>
          <div className="article__item__date">{node.frontmatter.date}</div>
          <hr className="solid" />
          <div className="article__item__description">
            {node.frontmatter.description}
          </div>
        </Link>
      ))}
    </div>
  )
}

export default Articles
