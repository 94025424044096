import React from "react"

import erikImage from "../images/erik.jpeg"

function About() {
  return (
    <section id="about-me">
      <h1>About Me</h1>
      <div id="about-me-content">
        <img id="about-me-img" src={erikImage} alt="Headshot of Erik" />
        <div id="about-me-txt">
          <p>
            My name is Erik. I'm a former process engineer that worked in
            manufacturing who made a career change into software engineering.
            Currently I'm the technical lead on the factory software team at{" "}
            <a href="https://formlabs.com/">Formlabs</a>. We are responsible for
            the software used in assembling, validating, calibrating and
            analyzing our 3D printers and peripheral devices.
          </p>
          <p>
            I think a lot about how manufacturing and software are similar and
            the lessons they can learn from each other. Sometimes I write about
            those things here.
          </p>
        </div>
      </div>
    </section>
  )
}

export default About
