import React from "react"

import About from "../components/About"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Articles from "../components/Articles"
import "../styles/modularscale.scss"
import "prismjs/themes/prism.css"
import "./index.scss"

const IndexPage = ({ data }) => {
  return (
    <>
      <div id="main-title">
        <div id="main-title-content">
          <h1>Manufacturing Code</h1>
          <p>Thoughts on software, manufacturing, etc.</p>
        </div>
      </div>
      <Layout>
        <SEO title="Home" />
        <About />
        <h2 className="index__header">Articles</h2>
        <Articles articles={data.allMarkdownRemark.edges} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query ArticleIndex {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            description
            image
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default IndexPage
